import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import {FeaturedBlogsStyles} from '../../styles/homePage/FeaturedBlogsStyles'
import { SectionTitle, RegularTitle } from '../typography/Title';
import ParagraphText from '../typography/ParagraphText';
import BlogGrid from '../blog/BlogGrid';

function FeaturedBlogs() {
    const data = useStaticQuery(graphql`
        {
            allSanityFeatured(filter: { _id: {eq: "featuredItems"}}){
                nodes {
                    blogs{
                        title
                        id
                        publishedAt
                        categories {
                            title
                            slug {
                                current
                            }
                        }
                        coverImage {
                            alt
                            asset{
                                gatsbyImageData
                            }
                        }
                        slug{
                            current
                        }
                    }
                }
            }
        }
    `);
    
    const featuredBlogs = data.allSanityFeatured.nodes[0] ? data.allSanityFeatured.nodes[0].blogs : null

    return (
        <FeaturedBlogsStyles>
            <div style={{display: "grid", justifyContent: "center"}}><div className='gradient2'></div></div>
            <SectionTitle>⚡Featured Blogs</SectionTitle>
            <div className='container' style={{display: "flex", justifyContent: "center",textAlign: 'center'}}>
            <ParagraphText className="special featuredBlogs__text ">
                Featured practical blogs for programmers. These are featured blogs for this week 
            </ParagraphText>
            </div>
            {
                featuredBlogs ? <BlogGrid blogs={featuredBlogs}/> : <RegularTitle>No Featured blogs available ☹️</RegularTitle>
            }
        </FeaturedBlogsStyles>
    )
}

export default FeaturedBlogs
