

export const services = [
    {
        name: "Websites",
        // icon: <TbWorld/>,
        description: "Backend and frontend development"
    },
    {
        name: "Applications",
        // icon: <IoLogoAppleAppstore/>,
        description: "Android and IOS app development"
    },
    {
        name: "Games",
        // icon: <CgGames/>,
        description: "Game development"
    }
]