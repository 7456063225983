import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { format } from 'date-fns'
import React from 'react'
import {BlogItemStyles} from '../../styles/blog/BlogItemStyles'
import ParagraphText from '../typography/ParagraphText'
import { RegularTitle, SectionTitle } from '../typography/Title'

function BlogItem({title, path, image, publishedAt, categories}) {

    
    return (
        <BlogItemStyles>
            <Link to={`/blogs/${path}`}>
                <GatsbyImage 
                    image={image.imageData}
                    alt={image.altText}
                    className="img"
                />
            </Link>
            <Link to={`/blogs/${path}`}>
                <RegularTitle>{title}</RegularTitle>
            </Link>
            {publishedAt && (
                <ParagraphText>
                    {format(new Date(publishedAt),'p, MMMM, dd, yyyy') }
                </ParagraphText>
            )}
            <ParagraphText className="categoriesText">
                {categories.map((item, index) => {
                    return (<span key={item.slug.current} className=''>
                        <Link to={`/categories/${item.slug.current}`}>
                            #{item.title}
                        </Link>
                        {index < categories.length - 1 ? " ": ""}
                    </span>)
                })}
            </ParagraphText>
        </BlogItemStyles>
    ) 
}

export default BlogItem
