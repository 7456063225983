import React from 'react'
import { ServicesSectionStyle } from '../styles/ServicesSectionStyle'
import ParagraphText from './typography/ParagraphText'
import {services} from '../constants/services'
import { RegularTitle } from './typography/Title'
import BackgroundImage from '../images/5.png'
import {BiWorld} from 'react-icons/bi';
import {BsPhoneFill} from 'react-icons/bs';
import {CgGames} from 'react-icons/cg'
import { Link } from 'gatsby'


function ServiceSection() {

    function GetIcon(name) {
        if (name === "Websites"){
            return <img className="serviceImage" src="https://images.unsplash.com/photo-1467232004584-a241de8bcf5d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80" alt="website image"/>
        }
        else if (name === "Applications"){
            return  <img className="serviceImage" src="https://images.unsplash.com/photo-1480694313141-fce5e697ee25?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80" alt="application image"/>
        }else if (name === "Games"){
            return <img className="serviceImage" src="https://images.unsplash.com/photo-1625750331870-624de6fd3452?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80" alt="game image" />
        }
    }

    return (
        <ServicesSectionStyle>
            {/* <ParagraphText>Auctux</ParagraphText> */}
            <div className='gradient'></div>
            <div className='mainContainer serviceSectionBack' >
                {
                    services.map((item)=>{
                        return (
                            
                                <Link to="/about" key={item.name} className='serviceCard'>
                                    {/* <span className='icon'>{item.icon}</span> */}
                                    {GetIcon(item.name)}
                                    <RegularTitle>{item.name}</RegularTitle>
                                    <p style={{fontSize: "15px", color: "#ccc"}}>{item.description}</p>
                                </Link>                           
                        )
                    })
                }
            </div>
        </ServicesSectionStyle>
    )
}

export default ServiceSection
