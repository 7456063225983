import styled from "styled-components";

export const ServicesSectionStyle = styled.div`
    margin-block: 20px;
    .mainContainer{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }
    
    .serviceCard{
        width: 30%;
        margin: 20px;
        background-color: #12182633;
        color: white;
        backdrop-filter: blur(20px);
        display: grid;
        justify-content:center ;
        align-items: center;
        border-radius: 12px;
        flex-wrap: wrap;
        padding-block: 10px;
        cursor: pointer;
    }
    .serviceCard:hover{
        background-color: var(--black2);
    }
    .serviceCard .icon{
        font-size: 120px;
    }
    .serviceCard {
        text-align: center;
    }
    @media (max-width: 900px){
        .serviceCard{
            width: 100%;
        }

    }
`