import React from 'react';
import ContactSection from '../components/ContactSection';
import FeaturedBlogs from '../components/homePage/FeaturedBlogs';
import HeroSection from '../components/homePage/HeroSection';
import TopCategories from '../components/homePage/TopCategories';
import LatestYoutubeVideo from '../components/LatestYoutubeVideo';
import SEO from '../components/SEOFile';
import ServiceSection from '../components/ServiceSection';
import ParagraphText from '../components/typography/ParagraphText';
import { SectionTitle } from '../components/typography/Title';
import AnnouncementSection from '../components/AnnouncementSection';

const IndexPage = () => (
  <>
    <SEO />
    <HeroSection/>
    <br/>
    <AnnouncementSection/>
    <br/>
    <LatestYoutubeVideo/>
    <br/>
    <div className='container'>
    <FeaturedBlogs/>
    <br/>
    <TopCategories/>
    <br/>
    <hr style={{borderColor: "var(--gray)"}}/>
    <br/>
    <div style={{marginBottom: "20px"}}>
      <br/>
      <SectionTitle>🧑‍💻 What we do 🧑‍💻</SectionTitle>
      <br/>
      <br/>
      <ServiceSection/>
    </div>

    <div style={{marginBottom: "20px"}}>
      <br/>
      <SectionTitle>✉️ Let's have a talk</SectionTitle>
      <br/>
      <br/>
      <ContactSection/>
    </div>
    
    </div>
  </>
);

export default IndexPage;
