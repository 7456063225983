import React from 'react'
import {BlogGridStyles} from '../../styles/blog/BlogGridStyles';
import { RegularTitle } from '../typography/Title';
import BlogItem from './BlogItem';

function BlogGrid({blogs}) {
    return (
        <BlogGridStyles>
            {blogs && blogs.map(blog => (
                <BlogItem 
                    key={blog.id} 
                    title={blog.title} 
                    path={blog.slug.current} 
                    categories={blog.categories} 
                    image={{imageData: blog.coverImage.asset.gatsbyImageData, altText: blog.coverImage.alt,}}
                    publishedAt={blog.publishedAt}
                />
            ))}
            {blogs.length === 0 && <RegularTitle> Error⚠️ Blogs not found ☹️</RegularTitle>}
        </BlogGridStyles>
    )
}

export default BlogGrid
