import styled from "styled-components";

export const AnnouncementSectionStyle = styled.div`
    .announcement-body{
        box-sizing: border-box;
        margin: 0;
        margin-top: 40px;
        margin-bottom: 30px;
        padding: 0;
        /* font-family: "Nunito", sans-serif; */

        /* background: #1f1f47; */
    }

    .announcement-container {
    /* min-height: 100vh; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    /* overflow: visible !important; */

    }

    .announcement-card {
        
    /* width: 500px; */
    max-width: 500px;
    min-height: 250px;
    background: rgba( 255, 255, 255, 0.15 );
    box-shadow: 0 8px 32px 0 rgba( 91, 38, 235, 0.27 );
    backdrop-filter: blur( 18px );
    -webkit-backdrop-filter: blur( 18px );
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    border-radius: 1rem;
    padding: 1.5rem;
    z-index: 10;
    color: whitesmoke;
    }

    .announcement-title {
    font-size: 2.3rem;
    margin-bottom: 1rem;
    text-align: center;
    }

    .announcement-subtitle {
    font-size: 1.9rem;
    margin-bottom: 2rem;
    text-align: center;
    }

    .announcement-btn {
    background: none;
    border: none;
    text-align: center;
    font-size: 1.6rem;
    color: whitesmoke;
    background-color: #2e70eb;
    padding: 0.8rem 1.8rem;
    border-radius: 2rem;
    cursor: pointer;
    }

    .announcement-btn:hover{
        background-color: #802eeb;

    }

    .announcement-blob {
    overflow: visible !important;
    position: absolute;
    width: 300px;
    height: 300px;
    background: linear-gradient(
        180deg,
        rgba(128, 36, 97, 0.42) 31.77%,
        #3b1ea5 100%
    );
    mix-blend-mode: color-dodge;
    -webkit-animation: move 25s infinite alternate;
            animation: move 25s infinite alternate;
    transition: 1s cubic-bezier(0.07, 0.8, 0.16, 1);
    }

    .announcement-blob:hover {
    width: 320px;
    height: 320px;
    -webkit-filter: blur(30px);
            filter: blur(30px);
    box-shadow:
        inset 0 0 0 5px rgba(255,255,255, 0.6),
        inset 80px 80px 0 0px #fa709a,
        inset 120px 120px 0 0px #4fc4a6,
        inset 200px 200px 0 0px #612bc5;
    }

    @-webkit-keyframes move {
    from {
        transform: translate(-100px, -50px) rotate(-90deg);
        border-radius: 24% 76% 35% 65% / 27% 36% 64% 73%;
    }

    to {
        transform: translate(500px, 100px) rotate(-10deg);
        border-radius: 76% 24% 33% 67% / 68% 55% 45% 32%;
    }
    }

    @keyframes move {
    from {
        transform: translate(-100px, -50px) rotate(-90deg);
        border-radius: 24% 76% 35% 65% / 27% 36% 64% 73%;
    }

    to {
        transform: translate(500px, 100px) rotate(-10deg);
        border-radius: 76% 24% 33% 67% / 68% 55% 45% 32%;
    }
    }

    .blinking {

    animation: opacity 1s ease-in-out infinite;
    opacity: 1;
    color: white;
    font-weight: bold;
    font-size: 2.1rem;
    }

    @keyframes opacity {
    0% {
    opacity: 1;
    color: #ff6600;
    }

    50% {
    opacity: 0;
    color: #d0ff00;
    }

    100% {
    opacity: 1;
    color: #ff6600;

    }
}
`