import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import {TopCategoriesStyles} from '../../styles/homePage/TopCategoriesStyles';
import CategoryGrid from '../category/CategoryGrid';
import ParagraphText from '../typography/ParagraphText';
import { SectionTitle, RegularTitle } from '../typography/Title';


function TopCategories(){
    const data = useStaticQuery(graphql`
        {
            allSanityFeatured(filter: {
                _id: {eq:"featuredItems"}
            }){
                nodes{
                    category{
                    id
                    title
                    slug{
                        current
                    }
                    _rawDescription
                }
                }
            }
        }
    `)

    const categories = data.allSanityFeatured.nodes[0] ?  data.allSanityFeatured.nodes[0].category : null;


    return <TopCategoriesStyles>
        <SectionTitle>🔥Top Categories</SectionTitle>
        
        <div className='container' style={{display: "flex", justifyContent: "center",textAlign: 'center'}}>
            <ParagraphText className="special featuredBlogs__text ">
            The most searched topics and categories on our website. 
            </ParagraphText>
            </div>
        {
            categories ? <CategoryGrid categories={categories}/> : <RegularTitle>No featured Categorie available ☹️</RegularTitle>
        }
    </TopCategoriesStyles>
}

export default TopCategories;