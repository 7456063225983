
import React from 'react';
import ParagraphText from './typography/ParagraphText';
import {SectionTitle} from './typography/Title'

function LatestYoutubeVideo({about}) {
    return (
        <div >
            <div style={{display: "grid", justifyItems: "center"}}><div className='gradient3'></div></div>

            <SectionTitle>✌️ Recent Youtube Video</SectionTitle>
            <div style={{textAlign: 'center'}}>
            <ParagraphText>Welcome to the bright Side of Programming, where you will make cool projects while learning to code.</ParagraphText>

            </div>
            <br/>
            <br/>
            <div className='container' style={{display: "flex", justifyContent: "center"}}>
            <iframe
            width={parseInt(560 * 1.3)}
            height={parseInt(315 * 1.3)}
            src="https://www.youtube.com/embed?listType=playlist&list=UUjPk9YDheKst1FlAf_KSpyA"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
            </div>
            <br/>
        </div>
    )
}

export default LatestYoutubeVideo
