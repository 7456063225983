import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import {HeroSectionStyles} from '../../styles/homePage/HeroSectionStyles'
import Button from '../buttons/Button'
import ParagraphText from '../typography/ParagraphText'
import { Canvas } from "@react-three/fiber";

import "./style.css"
import Cube from './SpinningBox'
function HeroSection() {
    return (
        <>
        <div className="canvas-container mt-4">
        <Canvas style={{position: "absolute", zIndex: "0"}}
          id="mycanvas"
          camera={{ fov: 75, position: [0, 0, 10], near: 0.1, far: 100 }}
        >
          <ambientLight />
          <pointLight position={[10, 10, 10]} />
          
          <Cube position={[-4, 0.5, 3]} />
        </Canvas>
      </div>
        <HeroSectionStyles>
            <div className='container'>
                <div className='hero__wrapper'>
                    <div className='left'>
                        <h1 className='hero__heading'>
                        We deliver excellence and innovation.
                        </h1>
                        <ParagraphText className="hero__text">
                        There is nothing we love more than working on challenging projects and sharing our knowledge and experience.
                        </ParagraphText>
                        <Button to="/blogs" tag={Link} className="hero__button">Explore</Button>
                    </div>
                    <div className='right'>
                        <StaticImage className="hero__image" src="../../images/bg-2.jpg" alt="heroImage" placeholder="blurred" objectPosition="50% 30%"/>
                    </div>
                </div>
            </div>
        </HeroSectionStyles>
        </>
    )
}

export default HeroSection
