import React from 'react';
import { AnnouncementSectionStyle } from '../styles/AnnouncementStyle';
import { Link } from 'gatsby';

function AnnouncementSection() {

    return (
        <AnnouncementSectionStyle>
            <div className="announcement-body">
                <div className="announcement-container">
                    <div className="announcement-card">
                        <h1 className="announcement-title">🚀 Get Ready for the Future! 👨‍💻</h1>
                        <p className="announcement-subtitle"> Programming & Electronics Formation and courses Launching Soon! 🔌 Stay Tuned for Updates & Registration Details!</p>
                        <p className="announcement-subtitle blinking">COMING SOON</p>
                        <div style={{display: "flex", justifyContent: "center"}}>
                        <Link to={`/blogs/exciting-news-tech-formation-and-courses-coming-soon`} className="announcement-btn">Read more</Link>
                        </div>
                    </div>
                    <div className="announcement-blob"></div>
                </div>
            </div>
        </AnnouncementSectionStyle>
    );
}

export default AnnouncementSection