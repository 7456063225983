import React, { useRef, useState, useMemo } from 'react'
import {useFrame,  } from '@react-three/fiber'
import {Vector3, Vector2} from 'three'


export default function Cube(props){
    const mesh = useRef();
    
    const [active, setActive] = useState(false);
    const [hover, setHover] = useState(false);

    const uniforms = useMemo(
        () =>(
            {
                iTime: {value: 0.0, type: 'f'},
                iResolution: {value: new Vector3(), type: 'v3'},
                iMouse: {value: new Vector2(), type: 'v2'},
            }
        ),
        []
    );

    useFrame((state)=>{
        const {clock, mouse} = state;

        // mesh.current.material.uniforms.iMouse.value.set(mouse.x, mouse.y);
        // mesh.current.material.uniforms.iResolution.value.set(window.innerWidth, window.innerHeight, 1);
        // mesh.current.material.uniforms.iTime.value = 0.4 * clock.getElapsedTime();
        mesh.current.rotation.y += 0.005;
        mesh.current.rotation.z += 0.008;
        mesh.current.rotation.x += 0.002;


    });

    const vertexshader = `
    varying vec2 vUv;
      void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
      }
      `;
    const fragmentshader = `
    void main(){
        gl_FragColor = vec4(0.3, 0.3, 0.5, 1);
    }
    `;

    return (
        <mesh
        {...props}
        ref={mesh}
        scale={active ? 1.5 : 1}
        onClick={(event) => setActive(!active)}
        onPointerOver={(event) => setHover(true)}
        onPointerOut={(event) => setHover(false)}
        >
            <boxBufferGeometry args={[3 , 3, 3, 3, 3, 3]}/>
            <shaderMaterial
                vertexShader={vertexshader}
                fragmentShader={fragmentshader}
                
                wireframe
                />
            {/* <OrbitControls/> */}
        </mesh>
    )
}